.social-icons {
    list-style: none;
    padding: 0;
}

.social-icons li {
    display: inline-block;
    margin-right: 1.2em;
    transition: 0.4s all ease-in-out;

    &:last-of-type {
        margin-right: 0;
    }

    &:hover {
        opacity: 0.6;
    }
}

.social-icons li img {
    width: auto; height: 20px;
    transition: 0.3s all @ease;

    @media(min-width:@medium) {
        height: 26px;
    }
}

.footer-column {
    display: block;
    width: 100%;
    margin-bottom: 2em;
    &:last-of-type {
        margin-bottom: 0;
    }
    @media(min-width:@medium) {
        display: inline-block;
        width: auto;
        margin: 0;
    }
}

.footer-menu {
    list-style: none;
    margin: 0; padding: 0;
}

.footer-menu li {
    margin-bottom: 0.5em;
    &:last-of-type {
        margin-bottom: 0;
    }
}

.footer-menu li a {
    text-decoration: none;
    color: @white;
    font-size: 1em;
}

.cookie-policy {
    max-width: 50em;
    opacity: 0.5;
}

.footer-menu__item {
    width: 100%;
    margin: 0 0 1.5em;
    @media (min-width: @medium) {
        width: auto;
    }
}

.footer-menu__nav,
.footer-menu__children {
    list-style: none;
    margin: 0; padding: 0;
}

.footer-menu__children {
    margin-top: 1em;
}

.footer-menu__dropdown__link {
    color: @white;
    font-size: 1em;
    text-decoration: none;
    @media (min-width: @medium) {
        font-size: 0.9em;
    }
}