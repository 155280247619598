.percentage {
    @media (max-width: @medium) {
        width: 100% !important;
    }
}

.percentage__head {
    background: @lightgrey;
    padding: .5em .25em .25em;
    height: 100%;
    // margin-bottom: 1em;
}

.percentage__inner {
    padding: .5em;
}



// Borders
@media (min-width: @medium) {
    .percentage__card {
        & .percentage__single {
            padding: 1em;
            border-right: 1px solid #ccc;
            height: 100%;
        }
        &:last-of-type .percentage__single {
            border-right: 0;
        }
    }

    .percentage__card {
        &.col-3\@medium:nth-child(4n+4) .percentage__single {
            border-right: 0 !important;
        }
        &.col-4\@medium:nth-child(3n+3) .percentage__single {
            border-right: 0 !important;
        }
        &.col-6\@medium:nth-child(2n+2) .percentage__single {
            border-right: 0 !important;
        }
    }
    
    .percentage__card.col-12\@medium {
        & .percentage__single {
            border-right: 0 !important;
            border-bottom: 1px solid #ccc;
        }
        &:last-of-type .percentage__single {
            border-bottom: 0;
        }
    }
}