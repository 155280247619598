.image-accordion {
    align-items: flex-start;
}

.image-accordion__image__inner {
    height: 100%; width: 100%;
    padding-bottom: 56.25%;
}

@media (min-width: @medium) {
    .flip-image .image-accordion__image {
        order: 2;
    }

    .flip-image .image-accordion__content {
        order: 1;
    }
}