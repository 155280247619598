.latest-vacancies__item {
    padding: 1.5em;
    border-top: 4px solid @red;
    flex-wrap: nowrap;
    height: 100%;

    transition: 1s transform @ease;
}

a .latest-vacancies__item:hover {
    cursor: pointer;
    @media (min-width: @medium) {
        transform: scale(1.025);
    }
}

.latest-vacancies__arrow {
    margin-left: 2em;
}

.job-details {
    overflow-wrap: break-word;
}

.latest-vacancies__show-hide {
    font-size: 0.8em;
    cursor: pointer;

    opacity: 0.7;
    transition: 0.3s all ease-in-out;
    &:hover {
        opacity: 1;
    }
}

.latest-vacancies .hide-row,
.latest-vacancies__show-hide,
.latest-vacancies__cta {
    display: none;
}

.latest-vacancies__show-hide.active,
.latest-vacancies__cta.active,
.show-more .hide-row {
    display: block;
}