.search {
    border-bottom: 1px solid @midgrey;
}

input {
    // reset 
    border: 0;
    border-radius: 3px;

    // default
    font-family: @main;
    font-size: 1.125em;
    // line-height: 1em;
    height: 50px;
    font-weight: 300;
    color: #333;
}

.search__input {
    padding: 1em 1em 1em 2em;
    margin-bottom: 1em;
    height: 50px;
    border: 1px solid @midgrey;
    background-repeat: no-repeat;
    background-position: 10px center;
    background-size: 0.75em auto;
    @media (min-width: @medium) {
        margin-bottom: 0;
        background-size: auto 1em;
    }
}

.search__submit {
    @media (min-width: @medium) {
        width: 12%;
    }
}

.search__title {
    background-image: url(../img/search-icon.svg);
}

.search__location {
    background-image: url(../img/map-pin.svg);
}