.image-text-card {
    margin-bottom: 1em;
    @media (min-width: @medium) {
        margin-bottom: 2em;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.image-text-card__content {
    padding: 30px;
    @media (min-width: @medium) {
        padding: 60px 30px;
    }
}

.image-text-card__image {
    min-height: 300px;
    border-bottom: 4px solid @red;
    @media (min-width: @medium) {
        min-height: 385px;
        border: 0;
        border-right: 4px solid @red;
    }
}

@media (min-width: @medium) {
    .image-text-card:nth-child(2n+2) .image-text-card__image  {
        order: 2;
        border: 0;
        border-left: 4px solid @red;
    }
}