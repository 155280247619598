.tns-controls {
    position: absolute;
    top: 50%; left: 0;
    width: 100%; height: auto;
    z-index: 50;

    display: flex;
    justify-content: space-between;
    padding: 0 1em;
    @media (max-width: @medium) {
        display:none;
    }
}

.tns-controls button {
    background: transparent;
    color: transparent;
    border: 0;
    width: 30px; height: 30px;
    border-radius: 100%;
    background-size: cover;
    .box-shadow;
}

.tns-controls button[data-controls="prev"]{
    background-image: url(../img/arrow-left-red.svg);
}

.tns-controls button[data-controls="next"]{
    background-image: url(../img/arrow-right-red.svg);
}
