
* {
	box-sizing:border-box;
}

video, img {
	display:block;
}

button, input {
	font-family:inherit;
}
