


.icon,
[class*="icon--"] {
	display:block;
	height: 1em;
	width: 1em;
	line-height:1;
	background-position: 50% 50%;
	background-size:1em 1em;
	background-size: contain;
	background-repeat: no-repeat;
}


// icon definitions

	// example

	// .icon--arrow {
	// 		background-image: url(../../images/___.svg);
	// }

	