.tns-visually-hidden {
    display: none;
}

.image-text-carousel {
    overflow: hidden;
    padding-bottom: 3em;
    @media (min-width: @medium) {
        padding-bottom: 0;
    }
}

.image-text-carousel__slides {
    align-items: flex-start;
}

.image-text-carousel__image {
    padding-bottom: 56.25%;
    width: 100%; height: auto;
    @media (min-width: @medium) {
        width: 50%; height: 100%;
        min-height: 600px;
        padding-bottom: 0;
    }
}

.image-text-carousel__content {
    padding: 2em;
    @media (min-width: @medium) {
        width: 50%; height: auto;

        padding-left: 5%;
        padding-right: 10vw;
    }
}

.image-text-carousel__navigation {
    position: absolute;
    bottom: 2em; right: 50%;
    margin: 0;
    transform: translateX(50%);

    @media (min-width: @medium) {
        bottom: 2em; right: 25%; left: auto;
    }
}

// .content-carousel__controls__right {
//     right: -1em;
//     background-image: url(../img/arrow-right-red.svg);
// }