
.accordion {
    margin-bottom: .75em;
    &:last-child {
        margin-bottom:0;
    }
} 

.accordion__button {
    width: 100%;
    border: 0;
    text-align: left;
    font-family: @main;
    background: @white;
    padding: 1.5em;
    cursor: pointer;
}

.accordion__content__inner {
    border-top: 1px solid #f7f7f7;
    background: @white;
    padding: 1.5em;   
}

.accordion__title {
    max-width: calc(100% - 50px);
}

.accordion__button::after {
    content: "";
    width: 20px;
    height: 20px;
    display: block;
    background: url('../img/accordion-plus.svg');
    background-size: cover;
    transition: 0.7s all @ease;

    @media (min-width: @medium) {
        width: 25px;
        height: 25px;
    }
}

.is-open .accordion__button::after {
    transform: rotate(45deg);
}



// .accordion-section {
//     max-width: 1000px;
//     margin: 0 auto;
// }

// .accordion {
//     margin-bottom: 1em;
//     background: #fff;
// }

// .accordion:hover {
//     cursor: pointer;
// }

// .accordion--title {
//     justify-content: space-between;
//     padding: 1em;
// }

// .accordion--title h4 {
//     width: calc(100% - 40px);
// }

// .accordion--icon {
//     transition: .3s all ease-in-out;
// }

// .active .accordion--icon {
//     transform: rotate(45deg);
// }

// .accordion--content {
//     padding: 1em;
//     border-top: 1px solid #f7f7f7;
// }