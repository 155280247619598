.privacy-title {
    margin-bottom: 1em;
}

.privacy-policy__country {
    padding: 1.5em;
    border-top: 4px solid @red;
    text-decoration: none;
    height: 100%;
}

.privacy-policy__flag {
    width: 40px; height: 40px;
    border-radius: 100%;
    margin-right: 20px;
    overflow: hidden;
}

.privacy-policy__title {
    width: calc(100% - 60px);
}