.full-feature {
    min-height: 32em;

    @media(min-width:@medium) {
        min-height: 40em;
    }
}

.full-feature::after {
    position: absolute;
    z-index: 7;
    content: "";

    width: 100%; height: 100%;
    top: 0; left: 0;
    background: rgba(0,0,0,.35);
    background: linear-gradient(90deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.25) 70%, rgba(0,0,0,0) 100%);
}

.full-feature__inner {
    position: relative;
    z-index: 10;

    max-width: 38em;
}