.article__module {
    .padding-bottom-5; 
    .margin-bottom-5;
    border-bottom: 3px solid @lightgrey;
}

.article-cta {
    padding: 1.5em;
    .margin-bottom-5;
    @media (min-width: @medium) {
        padding: 2.5em;
    }
}

.article__related {
    margin-bottom: 2em;
}

.categories {
    margin: -1em 0 0 -1em;
}

.categories__link {
    padding: 1em;
    margin: 1em 0 0 1em;

    border: 1px solid @midgrey;
    text-decoration: none;
    font-size: 1em;
    line-height: 1em;
    border-radius: 4px;
}

.article-gallery__image {
    padding-bottom: 56.25%;
}

.quote__inner {
    padding: 2em;
    border-left: 4px solid @red;
}

.quote__marks {
    width: 30px;
}