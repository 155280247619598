.wysiwyg-slider {
    overflow-x: hidden;
}

.wysiwyg-slide {
    margin: 0.5em 0;
}

.wysiwyg-slide__inner {
    padding: 2em;
    @media (min-width: @medium) {
        padding: 3em;
    }
}

.wysiwyg-slider__controls {
    padding: 0;
}

.wysiwyg-slider__controls button {
    position: relative;
}

.wysiwyg-slider__controls button[data-controls="prev"] {
    left: -15px;
}

.wysiwyg-slider__controls button[data-controls="next"] {
    right: -15px;
}