// basic button

.button {
	// reset
	text-decoration:none;
	vertical-align:middle;
	font-size:1.2rem;
	font-weight: 500;
	line-height:1.5em;
	color:inherit;
	cursor:pointer;
	border: 0;

	// layout
	overflow:hidden;
	position:relative;
	display:inline-block;
	z-index:1;

	// base styles
	.antialias;
	.ellipsis;
	color:inherit;
	background:transparent;
	text-align:center;
	transition-property: color, border-color, background;

	&::after {
		content: "";
		display: inline-block;
		position: relative;
		top: 0.25em; left: auto;

		width: 1.25em; height: 1.25em;
		margin-left: 0.5em;

		background-image: url('../img/arrow-right-red.svg');
		background-size: contain;
		background-repeat: no-repeat;
		transition: 0.3s all @ease;
	}

	&:hover::after {
		margin-left: 0.625em;
	}
	
	&, &::before {
		transition-duration: 0.35s;
		transition-timing-function: @ease;
	}

	&::before {
		transition-property: all;
	}


	&:active {
		transition:0;
		outline:0;
	}

	@media(max-width:@small-max) {
		font-size:0.9em;
	}

	// &:hover { background:fade(@teal, 7%) }
	// &:active { background:fade(@teal, 12%) }

	&:disabled {
		opacity: .5;
		cursor: not-allowed;
	}
}

.button--small {
	font-size: 1em;
}

.button--large {
	font-size: 1.2em;
}

.button--x-large {
	font-size: 1.4em;
}

.external--link {
	&:after {
		width: 1em; height: 1em;
		top: 0.15em; left: auto;

		background-image: url('../img/external-link.svg');
	}
}

// basic button style

.button--fill {
	padding: 10px 14px;
	border-radius: 3px;
	transition: 0.3s all ease-in-out;
	box-shadow: 0px 2px 8px 0 rgb(0 0 0 / 16%) !important;

	&:hover::after {
		margin-left: 0.5em;
	}
}

.fill-white {
	background-color: @white;
	color: @darkgrey;
	
	&:hover {
		background: @red;
		color: @white;
	}

	&:hover::after {
		background-image: url('../img/arrow-right-white.svg');
	}

	&.external--link:hover::after {
		background-image: url('../img/external-link-white.svg');
	}
}

.fill-red {
	background-color: @red;
	color: @white;

	&::after {
		background-image: url('../img/arrow-right-white.svg');
	}

	&:hover {
		background: @white;
		color: @darkgrey;
	}

	&:hover::after {
		background-image: url('../img/arrow-right-red.svg');
	}

	&.external--link::after {
		background-image: url('../img/external-link-white.svg');
	}

	&.external--link:hover::after {
		background-image: url('../img/external-link.svg');
	}
}




// Fill colours

.fill-white {
	background: #fff;
	color: #333;
	transition: 0.3s all ease-in-out;
}

.fill-white:hover {
	background: @red;
	color: @white;
}


.fill-red {
	background: @red;
}

// button types

.button--primary {}

.button--secondary {}


// modifiers

.button--block {
	display:block;
}