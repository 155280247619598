.text--narrow {
    max-width: 48em;
}

.text__center {
    margin: 0 auto;
}


// Articles

.author__image {
    width: 5em; height: 5em;
    border-radius: 100%;
    margin-right: 1em;
    overflow: hidden;
}