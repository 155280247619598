.calculation-card__single {
    padding: 1em;
    height: 100%;
    position: relative;
}

.plus-symbol::after, .multiply-symbol::after {
    content: "";
    position: absolute;
    width: 2em;
    height: 2em;
    background: #E60000;
    border-radius: 100%;
    top: calc(50% - 1em);
    right: -1.5em;
    z-index: 5;
    background-image: url('../img/vodafone-cross.svg');
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center center;
}

.plus-symbol::after {
    transform: rotate(45deg);
}

.calculation-card__cont:last-child .calculation-card__single::after {
    display: none;
}