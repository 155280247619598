.overflow-fixed {
    position: fixed;
    height: 100vh; width: 100vw;
}

.border-top {
    border-top: 4px solid @red;
}

.content .elementor-section:first-of-type .elementor-widget-container > .margin-y {
    margin-top: 1em;
    @media (min-width: @medium) { 
        margin-top: 3em;
    }
}

.elementor-container { // Makes all containers 100% width
    max-width: 100% !important;
}

.elementor-widget:not(:last-child) { // Removes margin-bottom when two modules are put into the same container
    margin-bottom: 0 !important;
}

.elementor-widget-wrap { // Removes padding from the above container
    padding: 0 !important;
}

.elementor-widget-wrap, .elementor-column, .elementor-container { // Changes Elements divs from 'flex' to 'block' to allow for margin stacking
    display: block !important;
}

// Admin
.elementor-add-template-button { // Hide template button
    display: none !important;
}

.elementor-preset.elementor-column { // Remove any columns except single col
    display: none !important;
    &:first-of-type {
        display: block !important;
    }
}

// Password protection

.post-password-form {
    background: @lightgrey;

    margin: 0 auto 30px;
    padding: 2em;
    border-radius: 4px;

    .page-width--small;
    .first-last;
}

.post-password-form label {
    font-weight: 500;
}

.post-password-form input[type='password'] {
    display: block;
    width: 100%;

    padding: 1em;
    border: 1px solid #D7D7D7;
}

.post-password-form input[type="submit"] {
    display: block;
    margin-top: 1em;

    .button;
    .button--fill;
    .fill-red;
}