.bonus-calculator {
    max-width: 720px;
    margin: 0 auto;

    border-top: 4px solid @red;
    padding: 1.2em;
    @media (min-width: @medium) {
        padding: 2em;
    }
}

.bonus-calculator h5,
.bonus-calculator label {
    color: #333;
}

.bonus-calculator__item {
    display: block;
    margin-bottom: 2em;
}

.bonus-calculator label,
.impact-percentage {
    font-weight: 700;
}

.calculator--input {
    margin-top: 0.25em;
    display: block;
    width: 100%;
    background: @lightgrey;
    height: 40px;
    padding: 0 0.75em;
}


// Impact rating

.impact-rating__cont {
    position: relative;
    margin: 1em 0;
}

.impact-rating__cont::after {
    content: "";
    position: absolute;
    height: 1px; width: 100%;
    background: #CCCCCC;
    top: 9px; left: 0;
    @media (max-width: @medium) {
        display: none;
    }
}

.bonus-calculator input[type=radio] {
    opacity: 0;
    width: 0; height: 0;
    display: none;
}

.impact-rating__option {
    text-align: center;
    position: relative;
    z-index: 10;

    @media (max-width: @medium) {
        text-align: left;
        display: flex;
        padding: 5px 0;
    }
}


.impact-rating__option span {
    font-weight: 300;
    display: block;
    width: 100%;
}

.impact-rating__option label {
    font-weight: 300;
}

.calculator__select {
    position: relative;

    width: 18px; height: 18px;
    margin-right: 5px;
    border-radius: 100%;
    border: 1px solid #CCCCCC;
    background: @lightgrey;
}

.impact-rating__select {
    .grid;
    .flex-center;
    margin: 0 auto 5px;

    @media (max-width: @medium) {
        display: inline-flex;
        top: 2px;
        margin: 0 5px 0 0;
    }
}

.impact-rating__option:hover,
.critical-skills__option:hover {
    cursor: pointer;
    & .calculator__select {
        border: 2px solid #B1B1B1;
    }
}

.active .calculator__select  {
    border: 1px solid #585858; 
    .grid;
    .flex-center;

    &::after {
        content: "";
        position: absolute;

        width: 8px; height: 8px;
        border-radius: 100%;
        background: #585858;
    }
}

.impact-rating__option.active .impact-rating__select {
    border: 1px solid #585858; 

    &::after {
        content: "";
        position: absolute;

        width: 8px; height: 8px;
        border-radius: 100%;
        background: #585858;
    }
}

.bonus-differential {
    position: relative;

    padding: 1em;
    border: 1px solid #C2C2C2;
    border-radius: 5px;
    @media (min-width: @medium) {
        margin-top: 10px;
        padding: 2em 1em;
    }
}

.bonus-differential::before {
    content: "";
    position: absolute;
    top: -11px; left: calc(50% - 10px);

    width: 20px; height: 20px;
    background: #fff;
    border-top: 1px solid #C2C2C2;
    border-right: 1px solid #C2C2C2;

    transform: rotate(-45deg);
    @media (max-width: @medium) {
        left: 30px;
    }
}

// Business performance
.calculator-percentage__label {
    text-align: right;
}

.calculator-smallprint {
    font-size: 14px;
    font-weight: 300;
}


/// radio button
.calculator__radio-button span {
    height: 40px; width: 100%;
    background: @lightgrey;
    font-weight: 300;
    cursor: pointer;
    opacity: 0.8;
}

.calculator__radio-button {
    &:last-of-type {
        border-right: 0;
    }
}

.calculator__radio-button.active span {
    background: #ccc !important;
    opacity: 1;
}

.calculator__radio-button:checked {
    background: gold;
}

// Range Slider 
.bonus-calculator input[type=range] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background: transparent; /* Otherwise white in Chrome */
    border: 0 !important;
}

.bonus-calculator input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
}

.bonus-calculator input[type=range]:focus {
    outline: none; 
    /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

.bonus-calculator input[type=range]::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: transparent; 
    border-color: transparent;
    color: transparent;
}

  /* Special styling for WebKit/Blink */
.bonus-calculator input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 0;
    height: 32px;
    width: 32px;
    border-radius: 100%;
    background-image: url('../img/input-slider-arrows.svg');
    background-color: #E60000;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 18px;
    cursor: pointer;
    margin-top: -14px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}

/* All the same stuff for Firefox */
.bonus-calculator input[type=range]::-moz-range-thumb {
    border: 0;
    height: 32px;
    width: 32px;
    border-radius: 3px;
    background-image: url('../img/input-slider-arrows.svg');
    background: #E60000;
    cursor: pointer;
}

/* All the same stuff for IE */
.bonus-calculator input[type=range]::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
}

.bonus-calculator input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    border: 1px solid #D7D7D7 !important;
    border-radius: 3px;
    background: #f7f7f7;
}

.bonus-calculator input[type=range]:focus::-webkit-slider-runnable-track {
    background: #f7f7f7;
}

.bonus-calculator input[type=range]::-moz-range-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    border: 1px solid #D7D7D7 !important;
    border-radius: 3px;
    background: #f7f7f7;
}

.bonus-calculator input[type=range]::-ms-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
}

.bonus-calculator input[type=range]::-ms-fill-lower {
    border: 1px solid #D7D7D7 !important;
    border-radius: 3px;
    background: #f7f7f7;
}

.bonus-calculator input[type=range]:focus::-ms-fill-lower {
    background: #f7f7f7;
}

.bonus-calculator input[type=range]::-ms-fill-upper {
    border: 1px solid #D7D7D7 !important;
    border-radius: 3px;
    background: #f7f7f7;
}

.bonus-calculator input[type=range]:focus::-ms-fill-upper {
    background: #f7f7f7;
}