
#lang-selector {
    max-width: 250px;
    position: relative;
}

.language-switcher {
    position: relative;
    padding: 1.1em 1em 0.9em;
    border: 1px solid #ccc;
    border-radius: 4px;
    z-index: 10;
}

.language-switcher.active {
    .box-shadow;
    border-color: @red;
}

.language-switcher__title {
    position: absolute;
    top: -10px; left: 0.5em;

    font-size: 0.94em;
    background: #fff;
    padding: 0 0.5em;
}

.language-switcher::after {
    content: '';
    width: 1em;
    height: 1em;
    // background: gold;
    position: absolute;
    right: 0.75em; top: 1.35em;

    background: url('../img/arrow-down-bold.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0.5;
}

.language-switcher__title {
    margin-right: 0.75em;
}

.language-switcher__flag {
    position: relative;
    top: 4px;
    width: 1.375em; height: 1.0625em;
    margin-right: 0.75em;
}

.language-switcher__dropdown {
    display: none;
    position: absolute;

    width: 100%;
    margin-top: 5px;
    border-radius: 5px;
    background: #fff;    
    z-index: 15;

    .box-shadow;
}

.language-switcher__dropdown.active {
    display: block;
}

.language-switcher__item {
    padding: 1em;
    border-bottom: 1px solid #ccc;
    text-decoration: none;
    &:hover {
        background: rgba(0,0,0,0.05);
    }
    cursor: pointer;
    &:last-child {
        border-bottom: 0;
    }
}
