.header {
    padding: 1em 0;
    position: absolute;
    width: 100%;
    z-index: 300;
    background: #fff;
    border-bottom: 1px solid @midgrey;
}

.header.sticky {
    position: fixed;
    top: 0;
}

.main {
    padding-top: 75px;
    @media(min-width:@medium) {
        padding-top: 75px;
    }    
    @media(min-width:@large) {
        padding-top: 81px;
    }
}

.header__logo {
    width: 2.6em;
    margin-right: 1.5em;
    transition: 0.4s all @ease;
    @media(min-width:@large) {
        width: 3em;
        margin-right: 2em;
    }
}