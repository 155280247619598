@import "./vendor/mediabox.css";

// global
@import "global/animations";
@import "global/body";
@import "global/breakpoints";
@import "global/debug";
@import "global/reset";
@import "global/typography";
@import "global/bg";
@import "global/slider";

// helper components
@import "components/a";
@import "components/button";
@import "components/grid";
@import "components/icon";
@import "components/page-width";
@import "components/spacing";

// components
@import "components/header";
@import "components/navigation";
@import "components/global-operations";
@import "components/hero";
@import "components/search";
@import "components/breadcrumbs";
@import "components/footer-cta";
@import "components/footer";
@import "components/text";
@import "components/card";
@import "components/full-feature";
@import "components/video";
@import "components/slider";
@import "components/image-text";
@import "components/image-text-card";
@import "components/image-accordion";
@import "components/accordion";
@import "components/separator";
@import "components/image-text-carousel";
@import "components/animated-statistic";
@import "components/testimonials";
@import "components/content-carousel";
@import "components/logo-carousel";
@import "components/latest-vacancies";
@import "components/icon-row";
@import "components/autocomplete";
@import "components/filters";
@import "components/articles";
@import "components/policies";
@import "components/language-switcher";
@import "components/bonus-calculator";
@import "components/calculation-card";
@import "components/tabbed-content";
@import "components/wysiwyg-carousel";
@import "components/percentage";

// main
@import "mixins";
@import "utility";
@import "variables";

// overrides
@import "overrides";