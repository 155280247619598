
.slider-nav {
    list-style: none;
    margin: 2em 0 0 0; 
    padding: 0;
}

.slider-nav li {
    display: inline-block;
    width: 0.7em; height: 0.7em;
    margin-right: 0.5em;
    background: transparent;
    border-radius: 100%;
    border: 1px solid @red;
    cursor: pointer;

    &:last-of-type {
        margin-right: 0;
    }

    &.tns-nav-active {
        background: @red;
    }
}

.bg--dark-grey .slider-nav li,
.bg--red .slider-nav li {
    border: 1px solid @white;
    &.tns-nav-active {
        background: @white;
    }
}

.tns-slider {
    overflow: hidden;
}

.tns-item {
    opacity: 0;
    transition: 0.5s all @ease;
}

.tns-slide-active {
    opacity: 1;
}

.tns-visually-hidden {
    display: none;
}



// Navigation 

.tns-nav {
    // margin-top: 3em;
    position: absolute;
    width: auto;
    margin: 0 auto !important;
    display: block;
    left: 50%;
    bottom: -3em;
    transform: translateX(-50%);

    // display: flex;
    // justify-content: center;
    // align-items: center;
}

.tns-nav button {
    appearance: none;
    -webkit-appearance: none;

    // width: 15px; height: 15px;
    padding: 7px;
    border: 1px solid @red;
    border-radius: 100%;
    background: transparent;

    margin: 0 5px;
    cursor: pointer;
}

.tns-nav button.tns-nav-active {
    background: @red;
}