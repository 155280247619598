
// text

.text-center { text-align:center }
.text-left { text-align:left }

.no-list {
	margin: 0; padding: 0;
	list-style: none;
}

// li

.reset-list {
	margin:0; padding:0;
	> li { list-style-type:none }
}

.margin-0 {
	margin: 0;
}

.width-100 {
	width:100%;
}

// position

.relative { position:relative }

.absolute-fit {
	position: absolute;
	top:0; right:0; bottom:0; left:0;
}


// show/hide

.hide { display:none !important }
.show { display:block !important }

.for-breakpoints({
    .hide\@@{breakpoint} { display:none !important }
    .show\@@{breakpoint} { display:block !important }
});


// container

.first > :first-child { margin-top:0 !important }
.last > :last-child { margin-bottom:0 !important }

.first-last {
	> :first-child { margin-top:0 !important }
	> :last-child { margin-bottom:0 !important }
}


// object fit

.object-fit, .object-fit--cover {
	object-fit:cover;
	object-position:50% 50%;
	width:100%; height:100% !important;
}

.object-fit--contain {
	&:extend(.object-fit);
	object-fit:contain;
}

.object-fit--absolute {
	&:extend(.object-fit);
	&:extend(.absolute-fit);
}


// Basic table
table {
    background-color: transparent;
    width: 100%;
    margin-bottom: 15px;
    font-size: .9em;
    border-spacing: 0;
    border-collapse: collapse
}

table td,table th {
    padding: 15px;
    line-height: 1.5;
    vertical-align: top;
    border: 1px solid #ccc
}

table th {
    font-weight: 700
}


table tfoot th,
table thead th,
table p, 
table span {
	font-size: 16px;
	line-height: 22px;
}

table caption+thead tr:first-child td,table caption+thead tr:first-child th,table colgroup+thead tr:first-child td,table colgroup+thead tr:first-child th,table thead:first-child tr:first-child td,table thead:first-child tr:first-child th {
    border-top: 1px solid #ccc
}

table tbody>tr:nth-child(odd)>td,table tbody>tr:nth-child(odd)>th {
    background-color: #f7f7f7
}

table tbody tr:hover>td,table tbody tr:hover>th {
    background-color: #e9e9e9
}

table tbody+tbody {
    border-top: 2px solid #ccc
}

@media (max-width: 767px) {
    table table {
        font-size:.8em
    }

    table table td,table table th {
        padding: 7px;
        line-height: 1.3
    }

    table table th {
        font-weight: 400
    }
}
