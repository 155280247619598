.tabs {
    border-bottom: 1px solid @lightgrey;
}

.tabs__button {
    background: transparent;
    padding: 0.6em 0.25em;

    font-size: 1.2em;
    border: 0;
    border-top: 4px solid @white;
    border-bottom: 1px solid @lightgrey;
    @media (min-width: @medium) {
        border-bottom: 0;
        border-right: 1px solid @lightgrey;
    }
}

.tabs__content__cont {
    padding: 1em;
    @media (min-width: @medium) {
        padding: 2em;
    }
}

.tabs__content {
    display: none;
}

.tabs__content.active {
    display: block;
}