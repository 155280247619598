.breadcrumbs {
    margin-bottom: 2em;
    position: relative;
    z-index: 99;
}

.breadcrumbs__item {
    display: inline-block;
    align-items: center;
    margin-right: 0.75em;
    padding-right: 1em;
    position: relative;
}

.breadcrumbs__link {
    margin-right: 0.75em;
    font-size: 0.9em;
    @media (min-width: @small) {
        font-size: 1em;
    }
}

a.breadcrumbs__link {
    text-decoration: none;
    color: @red;
    border-bottom: 1px solid @red;
}

.breadcrumbs__item--link:after {
    content: "";

    position: absolute;
    right: 0; top: 3px;

    display: inline-block;
    width: 1em; height: 1em;
    
    background-image: url(../img/arrow-right-bold.svg);
    background-size: 12px 8px;
    background-position: center center;
    background-repeat: no-repeat;
}