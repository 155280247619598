.filter {
    flex-wrap: nowrap;
    @media (max-width: @medium-max) {
        display:block;
    }
}

.filter__title {
    width: 100%;
    @media (min-width: @medium) {
        width: 140px;
        padding-right: 1em;
    }
}

.category-filter__cont {
    @media (min-width: @medium) {
        width: calc(100% - 140px);
    }
}

.filter__cont {
    position: relative;
    overflow: scroll;
    white-space: nowrap;

    margin-top: -0.5em;
    margin-left: -0.5em;
    width: 100%;
    @media (min-width: @medium) {
        white-space: normal;
    }
}

@media (max-width: @medium-max) {
    .filter__cont {
        padding-right: 50px;
    }

    .category-filter__cont {
        position: relative;
        &::after {
            content: "";
            position: absolute;
            top: 0; right: 0;

            width: 50px; height: 100%;
            background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6629026610644257) 35%, rgba(255,255,255,1) 100%);
        }
    }

}

.filter__cont::-webkit-scrollbar {
    display: none;
  }

.category-filter {
    padding: 0.6em 0.9em;
    font-size: 1.1em;
    font-weight: 300;

    border-radius: 3px;
    border: 1px solid @midgrey;
    background: transparent;
    margin-left: 0.5em;
    margin-top: 0.5em;

    cursor: pointer;

    &:before {
        content: "";
        display: inline-block; 
        width: 0; height: 13px;

        opacity: 0;
        // transition: 0.5s all ease-in-out;
    }
}

.category-filter.active {
    border-color: @red;
    color: @red;
    display: iniline-block;

    &:before {
        position: relative;
        width: 13px; height: 13px;
        top: 2px;

        margin-right: 0.25em;
        opacity: 1;

        background-image: url(../img/tick.svg);
        background-size: contain;
        background-repeat: no-repeat;
    }
}

.filter__clear {
    display: block;
    background: transparent;
    border: 0;
    font-size: 1em;
    font-weight: 300;
    color: @darkgrey;
    opacity: 0.5;
    transition: 0.3s opacity @ease;
    padding: 0;
    margin-bottom: 0.5em;
    cursor: not-allowed;
    &.active {
        opacity: 1;
        cursor: pointer;
    }
    &::before {
        content: "x";
        display: inline-block;
        position: relative;
        top: -1px;
        margin-right: 0.25em;
    }
}