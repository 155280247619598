
.autocomplete-list-container {
	position:relative;	
}

.autocomplete-list {
	margin:0; padding:0;
	list-style-type: none;
	z-index:2;

	position:absolute;
	top:100%; left:0;
	margin-top:-0.5em;
	width:100%;
	background:white;
	border-radius:3px;
	
	.box-shadow();
	overflow:hidden;

	@media(min-width:@medium) {
		margin-top:0.5em;	
	}
}

.autocomplete-result {
	padding:0.5em 1em;
	mark {font-weight:500; background:none;}
}

.autocomplete-result:hover {
	background:rgba(0,0,0,0.025);
}
.autocomplete-selected {
	background:rgba(0,0,0,0.05) !important;
}