

// custom font

	// @font-face {
	//     font-family: '_____';
	//     src: url('./../fonts/____.eot?#iefix') format('embedded-opentype'), 
	//          url('./../fonts/____.woff') format('woff'), 
	//          url('./../fonts/____.ttf') format('truetype'),
	//          url('./../fonts/____.otf') format('otf'), 
	//          url('./../fonts/____.svg#____') format('svg');
	//     font-weight: normal;
	//     font-style: normal;
	// }

	@font-face {
		font-family: 'Vodafone';
		src: url('../fonts/VodafoneLt-Regular.woff2') format('woff2'),
			url('../fonts/VodafoneLt-Regular.woff') format('woff');
		font-weight: 100;
		font-style: normal;
	}

	@font-face {
		font-family: 'Vodafone';
		src: url('../fonts/VodafoneRg-Regular.woff2') format('woff2'),
			url('../fonts/VodafoneRg-Regular.woff') format('woff');
		font-weight: 300;
		font-style: normal;
	}

	@font-face {
		font-family: 'Vodafone';
		src: url('../fonts/VodafoneRg-Bold.woff2') format('woff2'),
			url('../fonts/VodafoneRg-Bold.woff') format('woff');
		font-weight: 500;
		font-style: normal;
	}

	@font-face {
		font-family: 'Vodafone';
		src: url('../fonts/VodafoneExB-Regular.woff2') format('woff2'),
			url('../fonts/VodafoneExB-Regular.woff') format('woff');
		font-weight: 700;
		font-style: normal;
	}
	

	

// reset all headings to basic text styles. 
// use semantic <h1>, <h2> elements where appropriate based on content flow.
// use the .heading-1, .heading-2, etc. classes to style headings visually.

	h1, h2, h3, h4, h5, h6 {
		font-weight: 700;
		font-size:inherit;
		color: @darkgrey;
		-webkit-font-smoothing: antialiased;
	}


// base global text styles

	html {
		font-family:@main;
		font-weight: 300;

		-webkit-font-smoothing: antialiased;
		// use unitless line-height
		line-height:1.4; 
	}

	// the main text-based block elements in
	// use for any paragraph-style text

	p,
	table,
	blockquote,
	pre,
	address,
	dd, dt, dl, 
	caption, details, summary {
		font-size: 1em;
		color: @darkgrey;
		@media(min-width:@small) {}
		@media(min-width:@large) {
			font-size: 1.125em;
		}
	}

	p {
		margin: 0.65em 0 1.1em;
	}

	ul {
		padding-left: 1.05em;
	}

	li, ol {
		font-size: 1rem;
		@media(min-width:@large) {
			font-size: 1.125rem;
		}
	}

// custom headings

	.heading-1 {
		font-size:2.75em;
		line-height: 1.1em;
		@media(min-width:@x-small) {}
		@media(min-width:@small) {}
		@media(min-width:@medium) {}
		@media(min-width:@large) {
			font-size: 3.75em;
			margin-top: 0.65em;
			margin-bottom: 0.4em;
		}
	}

	.heading-2 {
		font-size:2.5em;
		line-height: 1.1em;
		margin: 0.35em 0;
		@media(min-width:@x-small) {}
		@media(min-width:@small) {}
		@media(min-width:@medium) {}
		@media(min-width:@large) {
			font-size: 2.45rem;
		}
	}

	.heading-3 {
		font-size:1.65em;
		line-height: 1.1em;
		margin: 0.35em 0;
		@media(min-width:@x-small) {}
		@media(min-width:@small) {}
		@media(min-width:@medium) {}
		@media(min-width:@large) {
			font-size: 2.2em;
			margin-top: 0.65em;
		}
	}

	.heading-4 {
		font-size:1.45em;
		line-height: 1.1em;
		margin-bottom: 0.4em;
		@media(min-width:@x-small) {}
		@media(min-width:@small) {}
		@media(min-width:@medium) {}
		@media(min-width:@large) {
			font-size: 1.85em;
		}
	}

	.heading-5 {
		font-size:1.3em;
		line-height: 1.2em;
		@media(min-width:@x-small) {}
		@media(min-width:@small) {}
		@media(min-width:@medium) {}
		@media(min-width:@large) {
			font-size: 1.5em;
			margin: 0.35em 0;
		}
	}

	.heading-6 {
		font-size:1.3em;
		margin: 0.35em 0;
		@media(min-width:@x-small) {}
		@media(min-width:@small) {}
		@media(min-width:@medium) {}
		@media(min-width:@large) {
			font-size: 1.3em;
		}
	}


// user-editable content sections
// for any CMS-editable body text areas, apply '.text-main' and style the basic
// elements specifically — this reduces effect of global styles on components

	.text-main {

		// basic heading styles
		h1, h2, h3, h4, h5, h6 {

		}

		// list styles
		li {
			margin-bottom: .5em;
		}

		h1, h2 { .heading-3; }
		h3 { .heading-4; }
		h4 { .heading-5; }
		h5, h6 { .heading-6; }
	}


// modifiers

.text-small {
	font-size: 0.9em;
}

.text-large {
	font-size: 1.1em;
}

.text-x-large {
	font-size: 1.3em;
}

.text-white,
.text-white p,
.text-white p a,
.text-white li,
.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6 {
	color:white;
}

.text-dark,
.text-dark p,
.text-dark h1,
.text-dark h2,
.text-dark h3,
.text-dark h4,
.text-dark h5,
.text-dark h6 {
	color:@darkgrey;
}

.text-red {
	color: @red;
}

.text-light {
	font-weight: 300;
}

.text-medium {
	font-weight: 400;
}

.text-strong {
	font-weight: 500;
}