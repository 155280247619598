.hero {
    height: 380px;
    @media(min-width:@medium) {
        height: 450px;
    }
    @media(min-width:@large) {
        height: 500px;
    }
}

.hero--large {
    height: 400px;
    @media(min-width:@medium) {
        height: 550px;
    }
    @media(min-width:@large) {
        height: 650px;
    }
}

.hero__inner, .hero__content {
    height: 100%;
}

.hero__content {
    position: relative;
    z-index: 10;
    max-width: 38em;
}

.hero__image {
    z-index: 5;
}

.banner-overlay::after {
    position: absolute;
    z-index: 7;
    content: "";

    width: 100%; height: 100%;
    top: 0; left: 0;
    background: rgba(0,0,0,.35);
    background: linear-gradient(90deg, rgba(0,0,0,0.45) 0%, rgba(0,0,0,0) 75%, rgba(0,0,0,0) 100%);
    @media(min-width:@medium) {
        background: linear-gradient(90deg, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 70%, rgba(0,0,0,0) 100%);
    }
}