.box-shadow {
    box-shadow: 0px 2px 8px 0 rgb(0 0 0 / 16%) !important;
}

.fill-white {
    background: @white;
}

.fill-red {
    background: @red;
}

.bg--red {
    background: @red;
}

.bg--white {
    background: @white;
}

.bg--light-grey {
    background: @lightgrey;
}

.bg--dark-grey {
    background: @darkgrey;
}