.content-carousel {
    overflow: hidden
}

.content-carousel .tns-inner {
    margin: 0 -10px !important;
}

.content-carousel__item {
    padding: 0 10px !important;
    margin-bottom: 1em;
}

.content-carousel__item__inner {
    padding: 2em;
    border-top: 4px solid @red;
    height: 100%;
}

.content-carousel__inner {
    margin-bottom: 3em;
}

.content-carousel__controls {
    position: absolute;
    top: 50%; left: 0;
    transform: translateY(-1em); 
    width:100%;

    z-index: 10;
}

.content-carousel__controls div {
    position: absolute;
    width: 2em; height: 2em;
    background-color: @red;
    border-radius: 100%;
    background-size: cover;
    cursor: pointer;
}

.content-carousel__controls__left {
    left: -1em;
    background-image: url(../img/arrow-left-red.svg);
}

.content-carousel__controls__right {
    right: -1em;
    background-image: url(../img/arrow-right-red.svg);
}

.content-carousel__controls div[aria-disabled="true"] {
    display: none;
}