

@grid-gutter: 1rem;
@grid-gutter-large: 4rem;
@grid-columns: 12;

.grid {
	display:flex;
	flex-wrap:wrap;
}

.flex-center {
	justify-content: center;
	align-items: center;
}

.align-center {
	align-items: center;
}

.space-between {
	justify-content: space-between;
}

.justify-center {
	justify-content: center;
}

[class*=col-] { width:100% }

.grid--gutter--horizontal {
	margin-left:-@grid-gutter;
	> [class*=col-] { padding-left:@grid-gutter }
}

.grid--gutter--horizontal--large {
	margin-left:-@grid-gutter-large;
	> [class*=col-] { padding-left:@grid-gutter-large }
}

.grid--gutter--vertical {
	margin-top:-@grid-gutter;
	> [class*=col-] { padding-top:@grid-gutter }
}

.grid--gutter--vertical--large {
	margin-top:-@grid-gutter-large;
	> [class*=col-] { padding-top:@grid-gutter-large }
}

.grid--gutter {
	.grid--gutter--horizontal;
	.grid--gutter--vertical;
}


.grid--gutter--large {
	.grid--gutter--horizontal;
	.grid--gutter--vertical;
	@media (min-width: @medium) {
		.grid--gutter--horizontal--large;
		.grid--gutter--vertical--large;
	}	
}

.col-flex { display:flex }


// generates .col-x classes

.for(@grid-columns, {
	.col-@{i} { width: (100% / @grid-columns * @i) }
});


// generates .col-x@breakpoint classes

.for-n-per-breakpoint(@grid-columns, {
	.col-@{i}\@@{breakpoint} { width: (100% / @grid-columns * @i) }
});