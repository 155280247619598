
// sets horizontal page spacing across all breakpoints

.page-width {	

	// center by default
	margin-left:auto;
	margin-right:auto;

	// 1em from viewport edge on mobile
	min-width:(@body-min-width - 2em);
	
	width: 100%;
	max-width:90vw;
	// @media(min-width:@small) { max-width:80vw }
	@media(min-width:@medium) {}
	@media(min-width:@large) {}	
}

.page-width--large {
	&:extend(.page-width);
	@media(min-width:@medium) { max-width:96vw }
}

.page-width--medium {
	&:extend(.page-width);
	@media(min-width:@medium) { max-width:70vw }
}

.page-width--small {
	&:extend(.page-width);
	@media(min-width:@medium) { max-width:60vw }
}

.page-width--x-small {
	&:extend(.page-width);
	@media(min-width:@medium) { max-width:50vw }
}