// Global --------------------------------------------------------

.site-menu__link {
    font-size: 20px;
    text-decoration: none;
}

.has-children .site-menu { // hide child items
    display: none;
}

.has-children.state > .site-menu {
    display: block;
    z-index: 5;
}

.mobile-button,
.mobile-buttons {
    display: none;
    cursor: pointer;
}

.site-menu__link {
    .grid;
    align-items: center;
    justify-content: space-between;
    
    font-size: 20px;
    line-height: 20px;
    padding: 10px 20px;
}

.has-children > .site-menu__link::after {
    content: "";
    display: block;

    width: 12px; height: 12px;
    transform: rotate(-90deg);
    background-image: url(../img/arrow-down-bold.svg);
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: center center;
}

.has-children:hover  > .site-menu__link::after {
    background-image: url(../img/arrow-down-bold-red.svg);
}



// Mobile --------------------------------------------------------

@media (max-width: @medium-max) {

    // Button
    .mobile-button {
        width: 27px; height: 27px;
        display: inline-block;
        background: transparent;
        border: 0;
        background-image: url(../img/mobile-menu.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 27px auto;
        opacity: 0.7;
        margin-left: 10px;

        @media (min-width: @medium) {
            margin-left: 20px;
        }
    }


    // Container
    .site-menu__cont {
        display: none;
        position: absolute;
        z-index: 100;
        right: -100%; top: 75px;
        height: calc(100vh - 75px); 
        width: 80vw;
        overflow: hidden;
        // transition: 0.5s all @ease;

        background: @white;
    }

    .site-menu__cont.state {
        display: block;
    }

    .site-menu__cont.open {
        right: 0;
    }



    .site-menu {
        width: 100%;
        padding: 25px 0;
    }

    // Show / hide menu (animation)

    [data-level="0"] { z-index: 10; }
    [data-level="1"] { z-index: 20; }
    [data-level="2"] { z-index: 30; }
    [data-level="3"] { z-index: 40; }

    .has-children > .site-menu {
        position: absolute;
        top: 0; left: 100%;
        height: 100%; width: 100%;
        background: @white;
        opacity: 0;
        transition: 0.5s all @ease;
    }

    .has-children.open > .site-menu {
        left: 0;
        opacity: 1;
    }

    
    // Menu item / link styling
    .site-menu__item {
        display: block;
        width: 100%; height: auto;

        white-space: nowrap;
    }

    .site-menu__item a{
        transition: 0.3s all @ease;
        &:hover {
            color: @red;
        }
    } 



    // Mobile navigation buttons
    .mobile-buttons {
        display: flex;
        position: absolute;
        bottom: 0; left: 0;
        width: 100%; height: auto;
        z-index: 50;

        background: @white;
        border-top: 1px solid @lightgrey;
    }

    .mobile-buttons__single {
        width: 50%; height: auto;
        padding: 0.75em;
        color: #333;

        background: @white;
        border: 0;
        -webkit-appearance: none;
        
        font-size: 0.9em;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.05em;

        &:first-of-type {
            border-right: 1px solid @lightgrey;
        }
    }

}


// Desktop --------------------------------------------------------

@media (min-width: @medium) {


    // Global
    .site-menu__link {
        transition: all 0.3s @ease;
        font-size: 18px;
        @media (min-width: @medium) {
            font-size: 19px;
        }
        @media (min-width: @large) {
            font-size: 22px;
        }
        &:hover {
            color: @red;
        }
    }

    // Parent items
    .site-menu[data-level="0"] > .site-menu__nav {
        display: flex;
    }

    .site-menu__item[data-level="0"] {
        margin-right: 1.25em;
        &:last-of-type {
            margin-right: 0;
        }
    }

    .site-menu__item[data-level="0"] > .site-menu__link {
        padding: 10px 0;
    }

    [data-level="0"] > .site-menu__link::after {
        transform: rotate(0deg);
        margin-left: 0.5em;
    }

    .state > .site-menu__link {
        font-weight: 500;
        color: @red;
    }

    .state > .site-menu__link::after {
        background-image: url(../img/arrow-down-bold-red.svg);
    }

    .site-menu__highlighted {
        padding-left: 1.5em;
        border-left: 1px solid @red;
    }

    // Child items

    .has-children > .site-menu {
        position: absolute;
    }

    [data-level="0"].has-children .site-menu__nav {
        padding: 20px 40px 25px 0px;
    }

    // Level 1

    .site-menu[data-level="1"] {
        left: 0; top: 75px;
        width: 100vw;

        padding: 0 2vw;
        background: @white;
        border-bottom: 1px solid @midgrey;
        transition: 0.5s all @ease !important;
        
    }


    .site-menu[data-level="1"] .site-menu__nav {
        width: 32vw;
    }

    // Level 2
    .site-menu[data-level="2"] {
        left: 50px; top: 0;
        transform: translateX(100%);
    }


    // Level 3
    .site-menu[data-level="3"] {
        left: 50px; top: 0;
        transform: translateX(100%);
    }
    

    // Animate

    .has-children > .site-menu {
        opacity: 0;
        transition: 0.5s all @ease;
    }

    .has-children.open > .site-menu {
        opacity: 1;
    }

    .site-menu[data-level="1"] .has-children.open > .site-menu {
        left: 0;
    }

    // Background

    .site-menu__background {
        display: none;
        position: absolute;
        top: 86px; left: 0;
        width: 100vw; height: calc(100vh - 86px);

        background: rgba(0,0,0,0.5);
        opacity: 0;
        transition: 0.5s all @ease;
    }

    .site-menu__background.state {
        display: block;
    }

    .site-menu__background.open {
        opacity: 1;
    }
}