
@keyframes fadeIn {
	0% { opacity:0 }
	100% { opacity:1 }
}

.onload--fadeIn {
	animation-name: fadeIn;
	animation-delay: 0.2s;
	animation-duration: 0.5s;
	animation-fill-mode:backwards;
}