
// default text link styles

a { color:@darkgrey; }

p a {
    text-decoration: underline !important;
}

a:hover {}

a:active {}


.simple-link {
    display: block;
    margin-top: 1.5em;

    font-size: 1.45em;
    font-weight: 500;
}