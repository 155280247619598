
// Menu

.global-operations {
    display: block;

    overflow: hidden;
    max-height: 0;
}

.global-operations.state {
    display: block;
    max-height: 0;
    transition: 2s all @ease;
}

.global-operations.open {
    max-height: 100vh;
    transition: 2s all @ease;
    padding-top: 2em;    
}

.country {
    text-decoration: none;
    &.disable {
        opacity: .4;
    }
}

.country__flag {
    width: 2em !important;
    margin-right: 0.5em;
}

.global-operations__home {
    position: absolute;
    top: 0; left: 0;

    text-decoration: none;
    padding: 12px 15px 10px;
    background: rgba(255,255,255,0.1);
    transition: 0.5s all @ease;
}

.global-operations__home:hover {
    background: rgba(255,255,255,0.05);
}

.global-operations__close {
    position: absolute;
    top: 0; right: 0;

    width: 2em; height: 2em;
    background: rgba(255,255,255,0.1);
    background-image: url(../img/close-icon.svg);
    background-size: 1.2em;
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;    
    border: 0;
}


// Button

.country-switcher {
    border: 0;
    transition: background 0.3s all @ease;
    padding: 8px;
    @media (min-width: @medium) {
        font-size: 16px;
        padding: 8px 10px;
    }
    @media (min-width: @large) {
        font-size: 18px;
        padding: 10px 14px;
    }
}

.country-switcher__label {
    @media (max-width: @x-small) {
        display: none;
    }
}

.country-switcher::after {
    display: none;
}

.nav-button--icon {
    width: 20px;
    height: 20px;
    background: url(../img/globe-icon.svg);
    background-size: 20px;
    background-position: center center;
    display: inline-block;
    position: relative;
    margin-left: 20px;
    margin-right: 25px;
    transition: .3s all ease-in-out;
    @media (max-width: @x-small) {
        margin-left: 0px;
    }
}

.nav-button--icon::before {
    content: "";
    height: 100%;
    width: 1px;
    background: #fff;
    display: inline-block;
    position: absolute;
    left: -10px;
    transition: .3s all ease-in-out;
    @media (max-width: @x-small) {
        display: none;
    }
}

.nav-button--icon::after {
    content: "";
    height: 20px;
    width: 20px;
    display: inline-block;
    position: absolute;
    right: -25px;
    background-image: url(../img/arrow-down-white.svg);
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center center;
    transition: .3s all ease-in-out;
}

.country-switcher:hover {
    & .nav-button--icon {
        background: url(../img/globe-icon-red.svg);
        background-size: 20px;
    }

    & .nav-button--icon::after {
        background-image: url(../img/arrow-down-red.svg);
    }

    & .nav-button--icon::before {
        background: @red;
    }
}