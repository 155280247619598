.icon-row__inner {
    position: relative;
    z-index: 15;
}

.icon-row__head {
    max-width: 760px;
    margin: 0 auto;
}

.icon-row__circle {
    height: 70px; width: 70px;
    background: @red;
    border-radius: 100%;
    overflow: hidden;
    margin: 0 auto;
    padding: 12px;
}

.icon-row__icon {
    // max-width: 50px !important;
}

.icon-row__overlay {
    position: absolute;
    height: 100%; width: 100%;
    top: 0; left: 0;

    background: rgba(0,0,0,0.7);
    z-index: 10;
}