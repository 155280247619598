.card {
    position: relative;
    transition: 1s transform @ease;
    &--link:hover {
        z-index: 50;
        cursor: pointer;
        @media (min-width: @medium) {
            transform: scale(1.05);
        }
    }
}

.card__inner {
    height: 100%;
}

.card__inner a {
    text-decoration: none;
}

.card__image {
    height: 0;
    padding-bottom: 56.25%;
}

.card__content {
    padding: 30px;
    border-top: 4px solid @red;
}

.card__icon {
    display: none;
}

a .card__icon { // only show when it's within an <a> tag
    width: 20px;
    height: 20px;
    display: inline-block;
    background-size: 18px auto;
    background-repeat: no-repeat;
    background-position: center left;
    position: relative;
    top: 2px;
    left: 5px;
    background-image: url(../img/arrow-right-red.svg);
}

a .card__icon--external {
    background-image: url(../img/external-link.svg);
}

.card--link--button {
    padding-bottom: 60px;
}

.card__button {
    position: absolute;
    bottom: 30px;
}