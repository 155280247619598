.video__thumb {
    padding-bottom: 56.25%;
    &::after {
        content: "";
        position: absolute;
        top: 0; left: 0;
        z-index: 5;

        width: 100%; height: 100%;
        background: rgba(0,0,0,0.3);
    }
}

.video__play {
    position: absolute;
    z-index: 10;
    top: 50%;

    width: 100px; height: auto;
    transform: translateY(-50%);
}

// Flip options
@media (min-width: @medium) {
    .video--flip .video__container {
        order: 1;
    }

    .video--flip .video__content {
        order: 2;
    }
}

// Articles
.article__video {
    max-width: 700px;
    display: block;
    margin: 2em auto 0;
}