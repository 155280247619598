.testimonial {
    overflow: hidden;
}

.testimonial__item {
    border-top: 4px solid @red;
    background: @white;
    align-items: flex-ssart;

    @media (min-width: @medium) {
        align-items: center;
        border-top: 0;
        border-left: 4px solid @red;
    }
}

.testimonial__image {
    height: auto;
    padding-bottom: 50%;
    @media (min-width: @medium) {
        height: 100%;
        padding-bottom: 0;
    }
}

.testimonial__content {
    padding: 2em;
    @media (min-width: @medium) {
        padding-left: 3em;
    }
}

.testimonial__title::before,
.testimonial__title::after {
    content: "";
    display: inline-block;
    width: 20px; height: 20px;
    background: url(../img/quote-marks.svg);
    background-size: contain;
    background-repeat: no-repeat;
}

.testimonial__title::before {
    margin-right: 5px;
    @media (min-width: @medium) {
        position: absolute;
        left: -25px; top: 0;
    }
}

.testimonial__title::after {
    margin-left: 5px;
    transform: rotate(180deg);
    position: relative;
    top: -5px;
}
