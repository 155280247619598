.logo-carousel {
    overflow: hidden;
}

.logo-carousel .tns-inner {
    margin: 0 -10px !important;
}

.logo-carousel__item {
    padding: 0 10px;
    opacity: 1 !important;
}

.logo-carousel__item__image  {
    height: 200px; width: auto;
}