.image-text__image__inner {
    width: 100%; height: auto;
    padding-bottom: 56.25%;
}

// Flip options
@media (min-width: @medium) {
    .image-text--flip .image-text__image {
        order: 2;
    }

    .image-text--flip .image-text__content {
        order: 1;
    }
}